/**
* Template Name: Yummy
* Template URL: https://bootstrapmade.com/yummy-bootstrap-restaurant-website-template/
* Updated: Jun 06 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
    --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Amatic SC",  sans-serif;
    --nav-font: "Inter",  sans-serif;
  }
  
  /* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
  :root { 
    --background-color: #ffffff; /* Background color for the entire website, including individual sections */
    --default-color: #212529; /* Default color used for the majority of the text content across the entire website */
    --heading-color: #37373f; /* Color for headings, subheadings and title throughout the website */
    --accent-color: #ce1212; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --contrast-color: #ffffff; /* The contrast color is used for elements when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors */
  }
  
  /* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
  :root {
    --nav-color: #7f7f90;  /* The default color of the main navmenu links */
    --nav-hover-color: #ce1212; /* Applied to main navmenu links when they are hovered over or active */
    --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #7f7f90; /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #ce1212; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
  }
  
  /* Template Custom Colors */
  :root {
    --alt-background-color: #1f1f24;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
    
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none !important;
    transition: 0.3s;
  }
  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: var(--nav-color) !important;
    text-decoration: none;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none !important;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 20px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 52px;
    margin-right: 8px;
    width: 125px;
  }
  
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
    font-family: var(--default-font);
  }
  
  .header .logo span {
    color: var(--accent-color);
    font-size: 36px;
  }
  
  .buy-btn .btn-getstarted,
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 14px;
    padding: 8px 26px;
    margin: 0;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 20px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu>ul>li {
      white-space: nowrap;
      padding: 15px 14px;
    }
  
    .navmenu>ul>li:last-child {
      padding-right: 0;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      font-size: 15px;
      padding: 0 2px;
      font-family: var(--nav-font);
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
      position: relative;
      cursor: pointer;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu>ul>li>a:before {
      content: "";
      position: absolute;
      height: 2px;
      bottom: -6px;
      left: 0;
      background-color: var(--nav-hover-color);
      visibility: hidden;
      width: 0px;
      transition: all 0.3s ease-in-out 0s;
    }
  
    .navmenu a:hover:before,
    .navmenu li:hover>a:before,
    .navmenu .active:before {
      visibility: visible;
      width: 100%;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: color-mix(in srgb, var(--nav-color) 80%, black 50%);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Mobile Navigation */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    --background-color: var(--alt-background-color);
    --default-color: #ffff;
    --heading-color: #ffff;
    --heading-font: var(--default-font);
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    padding: 40px 0;
    position: relative;
  }
  
  .footer .icon {
    color: var(--accent-color);
    margin-right: 15px;
    font-size: 24px;
    line-height: 0;
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  .footer .address p {
    margin-bottom: 0px;
  }
  
  .footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .footer .copyright {
    padding-top: 20px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .copyright p {
    margin-bottom: 0;
  }
  
  .footer .credits {
    margin-top: 5px;
    font-size: 13px;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    background-color: var(--background-color);
    transition: all 0.6s ease-out;
    width: 100%;
    height: 100vh;
  }
  
  #preloader:before,
  #preloader:after {
    content: "";
    position: absolute;
    border: 4px solid var(--accent-color);
    border-radius: 50%;
    animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  #preloader:after {
    animation-delay: -0.5s;
  }
  
  @keyframes animate-preloader {
    0% {
      width: 10px;
      height: 10px;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      opacity: 1;
    }
  
    100% {
      width: 72px;
      height: 72px;
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    --background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 120px 0;
    text-align: center;
    position: relative;
  }
  
  .page-title h1 {
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: var(--default-font);
  }
  
  .page-title .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .page-title .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .page-title .breadcrumbs ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 92px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 56px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    display: inline-block;
    text-transform: uppercase;
    font-family: var(--default-font);
  }
  
  .section-title p {
    color: var(--heading-color);
    margin: 10px 0 0 0;
    font-size: 48px;
    font-weight: 500;
    font-family: var(--heading-font);
  }
  
  .section-title p .description-title {
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    --background-color: color-mix(in srgb, var(--default-color), transparent 94%);
    width: 100%;
    min-height: 60vh;
    position: relative;
    padding: 60px 0;
    display: flex;
    align-items: center;
    background-image: url(../../../public/assets/img/bg.jpg);
  }
  
  .hero h1 {
    margin: 0;
    font-size: 64px;
    font-weight: 700;
  }
  
  .hero p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin: 5px 0 30px 0;
    font-size: 20px;
    font-weight: 400;
  }
  
  .hero .btn-get-started {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 12px 28px;
    border-radius: 50px;
    transition: 0.5s;
    box-shadow: 0 8px 28px color-mix(in srgb, var(--accent-color), transparent 80%);
  }
  
  .hero .btn-get-started:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
    box-shadow: 0 8px 28px color-mix(in srgb, var(--accent-color), transparent 55%);
  }
  
  .hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--default-color);
    font-weight: 500;
  }
  
  .hero .btn-watch-video i {
    color: var(--accent-color);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero .btn-watch-video:hover {
    color: var(--accent-color);
  }
  
  .hero .btn-watch-video:hover i {
    color: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  .hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 640px) {
    .hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .hero p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    .hero .btn-get-started,
    .hero .btn-watch-video {
      font-size: 13px;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about {
    background-image: url(../../../public/assets/img/bg.jpg);
  }
  .about h3 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .about .book-a-table {
    text-align: center;
    border: 4px solid color-mix(in srgb, var(--default-color), transparent 30%);
    padding: 22px;
  }
  
  .about .book-a-table h3 {
    font-family: var(--default-font);
    margin: 0 0 0 0;
    font-size: 24px;
  }
  
  .about .book-a-table p {
    color: var(--accent-color);
    font-weight: 500;
    font-size: 28px;
    margin: 0;
  }
  
  .about .fst-italic {
    /* color: color-mix(in srgb, var(--default-color), var(--contrast-color) 50%); */
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 0 0 10px 30px;
    position: relative;
  }
  
  .about .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: var(--accent-color);
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 75%) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about .play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about .play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about .play-btn:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  .about .play-btn:hover:after {
    border-left: 15px solid var(--accent-color);
    transform: scale(20);
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Why Us Section
  --------------------------------------------------------------*/
  .why-us {
    --background-color: color-mix(in srgb, var(--default-color), transparent 94%);
  }
  
  .why-us .why-box {
    color: var(--contrast-color);
    background: var(--accent-color);
    padding: 30px;
  }
  
  .why-us .why-box h3 {
    color: var(--contrast-color);
    font-family: var(--default-font);
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .why-us .why-box p {
    margin-bottom: 30px;
  }
  
  .why-us .why-box .more-btn {
    display: inline-block;
    background: color-mix(in srgb, var(--contrast-color), transparent 85%);
    padding: 8px 40px 10px 40px;
    color: var(--contrast-color);
    transition: all ease-in-out 0.4s;
    border-radius: 50px;
  }
  
  .why-us .why-box .more-btn i {
    font-size: 14px;
  }
  
  .why-us .why-box .more-btn:hover {
    color: var(--accent-color);
    background: var(--contrast-color);
  }
  
  .why-us .icon-box {
    background-color: var(--contrast-color);
    text-align: center;
    padding: 40px 30px;
    width: 100%;
    height: 100%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .why-us .icon-box i {
    color: var(--accent-color);
    font-size: 32px;
    margin-bottom: 30px;
    background: color-mix(in srgb, var(--accent-color), transparent 95%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    transition: 0.3s;
  }
  
  .why-us .icon-box h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 30px 0;
    font-family: var(--default-font);
  }
  
  .why-us .icon-box p {
    font-size: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .why-us .icon-box:hover i {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
  .stats {
    --default-color: #ffffff;
    --background-color: #000000;
    position: relative;
    padding: 120px 0;
  }
  
  .stats img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .stats:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 40%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .stats .container {
    position: relative;
    z-index: 3;
  }
  
  .stats .stats-item {
    padding: 30px;
    width: 100%;
  }
  
  .stats .stats-item span {
    font-size: 48px;
    display: block;
    color: var(--default-color);
    font-weight: 700;
  }
  
  .stats .stats-item p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  /*--------------------------------------------------------------
  # Menu Section
  --------------------------------------------------------------*/
  .menu .nav-tabs {
    border: 0;
  }
  
  .menu .nav-link {
    background-color: var(--background-color);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0 15px;
    padding: 10px 5px;
    transition: 0.3s;
    border-radius: 0;
    cursor: pointer;
    height: 100%;
    border: 0;
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  @media (max-width: 575px) {
    .menu .nav-link {
      margin: 0 10px;
      padding: 10px 0;
    }
  }
  
  .menu .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .menu .nav-link h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    font-family: var(--default-font);
  }
  
  @media (max-width: 575px) {
    .menu .nav-link h4 {
      font-size: 16px;
    }
  }
  
  .menu .nav-link:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .menu .nav-link.active {
    background-color: var(--background-color);
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .menu .tab-content .tab-header {
    padding: 30px 0;
  }
  
  .menu .tab-content .tab-header p {
    font-size: 14px;
    text-transform: uppercase;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin-bottom: 0;
  }
  
  .menu .tab-content .tab-header h3 {
    font-size: 48px;
    font-weight: 700;
    color: var(--accent-color);
  }
  
  .menu .tab-content .menu-item {
    text-align-last: center;
  }
  
  .menu .tab-content .menu-item .menu-img {
    padding: 0 60px;
    margin-bottom: 15px;
  }
  
  .menu .tab-content .menu-item h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px;
    font-family: var(--default-font);
  }
  
  .menu .tab-content .menu-item .ingredients {
    font-family: var(--nav-font);
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 5px;
  }
  
  .menu .tab-content .menu-item .price {
    font-size: 24px;
    font-weight: 700;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
  .testimonials {
    --background-color: color-mix(in srgb, var(--default-color), transparent 94%);
  }
  
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonials .testimonial-item .testimonial-content {
    border-left: 3px solid var(--accent-color);
    padding-left: 30px;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    border-radius: 50%;
    border: 4px solid var(--background-color);
    margin: 0 auto;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin: 0 0 10px 0;
  }
  
  .testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
  }
  
  .testimonials .swiper-wrapper {
    height: auto;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 30px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Events Section
  --------------------------------------------------------------*/
  .events .container-fluid {
    padding: 0;
  }
  
  .events .event-item {
    background-size: cover;
    background-position: cente;
    min-height: 600px;
    padding: 30px;
  }
  
  @media (max-width: 575px) {
    .events .event-item {
      min-height: 500px;
    }
  }
  
  .events .event-item:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    inset: 0;
  }
  
  .events .event-item h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #ffffff;
    position: relative;
  }
  
  .events .event-item .price {
    color: #ffffff;
    border-bottom: 2px solid var(--accent-color);
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 15px;
    position: relative;
  }
  
  .events .event-item .description {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
  }
  
  .events .swiper-wrapper {
    height: auto;
  }
  
  .events .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .events .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Chefs Section
  --------------------------------------------------------------*/
  .chefs .team-member {
    box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 92%);
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .chefs .team-member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .chefs .team-member .member-img:after {
    position: absolute;
    content: "";
    left: -1px;
    right: -1px;
    bottom: 0;
    height: 100%;
    /* background: url(../img/team-shape.svg) no-repeat center bottom; */
    background-size: contain;
    z-index: 1;
  }
  
  .chefs .team-member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: 0.5s;
    background: color-mix(in srgb, var(--background-color), transparent 60%);
    z-index: 2;
  }
  
  .chefs .team-member .social a {
    transition: color 0.3s;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
  }
  
  .chefs .team-member .social a:hover {
    color: var(--default-color);
  }
  
  .chefs .team-member .social i {
    font-size: 18px;
  }
  
  .chefs .team-member .member-info {
    padding: 10px 15px 20px 15px;
  }
  
  .chefs .team-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    font-family: var(--default-font);
  }
  
  .chefs .team-member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .chefs .team-member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .chefs .team-member:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  .chefs .team-member:hover .social {
    right: 8px;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Book A Table Section
  --------------------------------------------------------------*/
  .book-a-table .reservation-img {
    min-height: 500px;
    background-size: cover;
    background-position: center;
  }
  
  .book-a-table .reservation-form-bg {
    background: color-mix(in srgb, var(--default-color), transparent 97%);
  }
  
  .book-a-table .php-email-form {
    padding: 30px;
  }
  
  @media (max-width: 575px) {
    .book-a-table .php-email-form {
      padding: 20px;
    }
  }
  
  .book-a-table .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .book-a-table .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .book-a-table .php-email-form .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .book-a-table .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
  }
  
  .book-a-table .php-email-form input[type=text],
  .book-a-table .php-email-form input[type=email],
  .book-a-table .php-email-form input[type=number],
  .book-a-table .php-email-form input[type=date],
  .book-a-table .php-email-form input[type=time],
  .book-a-table .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 20%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .book-a-table .php-email-form input[type=text]:focus,
  .book-a-table .php-email-form input[type=email]:focus,
  .book-a-table .php-email-form input[type=number]:focus,
  .book-a-table .php-email-form input[type=date]:focus,
  .book-a-table .php-email-form input[type=time]:focus,
  .book-a-table .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .book-a-table .php-email-form input[type=text]::placeholder,
  .book-a-table .php-email-form input[type=email]::placeholder,
  .book-a-table .php-email-form input[type=number]::placeholder,
  .book-a-table .php-email-form input[type=date]::placeholder,
  .book-a-table .php-email-form input[type=time]::placeholder,
  .book-a-table .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .book-a-table .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 14px 60px;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .book-a-table .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Gallery Section
  --------------------------------------------------------------*/
  .gallery {
    --background-color: color-mix(in srgb, var(--default-color), transparent 94%);
    overflow: hidden;
  }
  
  .gallery .swiper-wrapper {
    height: auto;
  }
  
  .gallery .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .gallery .swiper-pagination .swiper-pagination-bullet {
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    border: 0;
    width: 12px;
    height: 12px;
    opacity: 1;
  }
  
  .gallery .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
    opacity: 1;
  }
  
  .gallery .swiper-slide-active {
    text-align: center;
  }
  
  @media (min-width: 992px) {
    .gallery .swiper-wrapper {
      padding: 40px 0;
    }
  
    .gallery .swiper-slide-active {
      background: var(--background-color);
      border: 6px solid var(--accent-color);
      padding: 4px;
      z-index: 1;
      transform: scale(1.2);
      transition: none;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-item {
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 20px 30px;
  }
  
  .contact .info-item .icon {
    color: var(--contrast-color);
    background-color: var(--accent-color);
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .contact .info-item h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
    font-family: var(--default-font);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .info-item .social-links a {
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    margin: 4px 6px 0 0;
    transition: 0.3s;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .contact .info-item .social-links a:hover {
    color: var(--accent-color);
  }
  
  .contact .php-email-form {
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
    margin-top: 30px;
  }
  
  @media (max-width: 575px) {
    .contact .php-email-form {
      padding: 20px;
    }
  }
  
  .contact .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
  .starter-section {
    /* Add your styles here */
  }

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 12px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

.section-bg {
  background-color: #f3f5fa;
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

.slick-next {
  right: 10px !important;
}
.slick-prev {
  left: 15px !important;
}
.sld-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  border-radius: 2%;
  height: 325px;
}
.sld-container img {
  /* width: 50%; */
}
.bnd-image img{
  width: 335px;
  height: 200px;
}

@media (max-width: 575px) {
  .bnd-image img{
    width: 335px;
    height: 75px;
  }
}
.discount-txt {
  background-color: lightskyblue;
}
.slick-slide {
  padding: 0 5px;
}
